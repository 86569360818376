import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import ContactSection from '../containers/ContactSection';
import Footer from '../containers/Footer';
import BackgroundShape from '../containers/BackgroundShape';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('تواصل معنا', 'Contact us')} />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>
                <BackgroundShape
                    position="right"
                    className="background-shape__contact"
                />
                <ContactSection />
                <Footer />
            </ContentWrapper>
        </ThemeProvider>
    );
};
