import styled from 'styled-components';

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (max-width: 801px) {
        margin-right: 0;
        flex-direction: row;
    }

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50%;
        padding: 8px;
        ${({ lang }) =>
            lang === 'ar' ? 'margin-left: 12px;' : 'margin-right: 12px;'}
        ${({ iconBackgroundColor }) =>
            'background-color:' + iconBackgroundColor + ';'}

        & > * {
            font-size: 18px;

            @media (max-width: 1211px) {
                font-size: 16px;
            }
        }

        @media (max-width: 801px) {
            margin-right: 0;
        }
    }

    & > p {
        font-size: 18px;
        @media (max-width: 1211px) {
            font-size: 17px;
        }
    }
`;
export default InfoWrapper;
