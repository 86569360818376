import React from 'react';
import Container from '../../common/components/UI/Container';
import Input from '../../common/components/Input';
import { __ } from '../../common/utils/LanguageHelper';
import ContentWrapper from './ContactSection.style';
import Button from '../../common/components/Button';
import ImageFrame from '../ImageFrame';
import Info from '../Info';
import Select from '../../common/components/Select';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';

import AwasulStoryImage from '../../common/assets/image/DSC_6601.png';

const ContactSection = () => {
    const { locale } = useIntl();

    const SOCIAL_LIST = [
        {
            title: 'info@awasul.com',
            icon: <EmailIcon />,
            iconBackgroundColor: '#ec2b45',
        },
        {
            title: '966126825858+',
            icon: <CallIcon />,
            iconBackgroundColor: '#72b13a',
        },
    ];

    const companySelectOptions = [
        {
            value: __('وزارة', 'Governmental Ministry'),
            label: __('وزارة', 'Governmental Ministry'),
        },
        {
            value: __('شركة', 'Company'),
            label: __('شركة', 'Company'),
        },
        {
            value: __('مؤسسة', 'Corporation'),
            label: __('مؤسسة', 'Corporation'),
        },
        {
            value: __('هيئة', 'Body'),
            label: __('هيئة', 'Body'),
        },
        {
            value: __('منظمة', 'Organisation'),
            label: __('منظمة', 'Organisation'),
        },
        {
            value: __('مصنع', 'Factory'),
            label: __('مصنع', 'Factory'),
        },
        {
            value: __('قطاع خاص', 'Private sector'),
            label: __('قطاع خاص', 'Private sector'),
        },
        {
            value: __('قطاع', 'Section'),
            label: __('قطاع', 'Section'),
        },
        {
            value: __('حكومي', 'governmental'),
            label: __('حكومي', 'governmental'),
        },
        {
            value: __('شركة ناشئة', 'Start-up company'),
            label: __('شركة ناشئة', 'Start-up company'),
        },
        {
            value: __('أخرى', 'Other'),
            label: __('أخرى', 'Other'),
        },
    ];

    const EmployeesCountSelectOptions = [
        {
            value: '50-00',
            label: '50-00',
        },
        {
            value: '100-500',
            label: '100-500',
        },
        {
            value: '300-100',
            label: '300-100',
        },
        {
            value: '1000-500',
            label: '1000-500',
        },
        {
            value: '3000-1000',
            label: '3000-1000',
        },
        {
            value: '1000000-3000',
            label: '1000000-3000',
        },
    ];

    const ContactSchema = Yup.object().shape({
        company: Yup.object().required(
            __('اختر المنشأة', 'Select your company')
        ),
        employees_count: Yup.object().required(
            __('اختر عدد الموظفين', 'Select your number of Employee')
        ),
        company_name: Yup.string().required(
            __('ادخل اسم شركتك', 'Enter your company name')
        ),
        mobile: Yup.string().test(
            'len',
            __('ادخل رقم جوال صالح', 'Enter valid phone number'),
            (val) => !val || val.length === 13
        ),
        email: Yup.string()
            .email(
                __(
                    'ادخل بريد إلكتروني صالح مثال: you@example.com',
                    'Enter valid email EX: you@example.com'
                )
            )
            .required(__('ادخل البريد الإلكتروني', 'Enter your email')),
        website: Yup.string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                __('ادخل رابط صالح', 'Enter valid url!')
            )
            .required(__('ادخل رابط موقعك', 'Enter your company link')),
        message: Yup.string().required(
            __(
                'ادخل الرسالة التي تريد إرسالها إلى فريقنا',
                'Enter the message you want to send to our team'
            )
        ),
    });

    return (
        <Container>
            <ContentWrapper>
                <div className="textWrapper" lang={locale}>
                    <h2>
                        {__(
                            'كيف نستطيع خدمتك ويهمنا التواصل معك عبر فريق عمل متكامل على مدار 24/7 للمساعدة والدعم',
                            'How can we serve you and we are interested in communicating through an integrated team work 24/7'
                        )}
                    </h2>
                    <div className="socialWrapper">
                        {SOCIAL_LIST.map(
                            ({ title, icon, iconBackgroundColor }, i) => (
                                <Info
                                    key={i}
                                    title={title}
                                    icon={icon}
                                    iconBackgroundColor={iconBackgroundColor}
                                />
                            )
                        )}
                    </div>
                    <div className="imageWrapper">
                        <ImageFrame
                            imageUrl={AwasulStoryImage}
                            alt="Awasul team"
                        />
                    </div>
                </div>

                <Formik
                    initialValues={{
                        company: '',
                        employees_count: '',
                        company_name: '',
                        mobile: '',
                        email: '',
                        website: '',
                        message: '',
                    }}
                    validationSchema={ContactSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        try {
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                // Clear current step form data
                                resetForm('');
                                setSubmitting(false);
                            }, 400);
                        } catch (error) {
                            if (error.response.status === 500) {
                                return navigate('/500');
                            }
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        isSubmitting,
                        setValues,
                    }) => (
                        <form className="inputWrapper" onSubmit={handleSubmit}>
                            <Select
                                options={companySelectOptions}
                                labelText={__(
                                    'إختر المنشأة',
                                    'Select the company'
                                )}
                                name="company"
                                value={values.company}
                                onChange={(value) => {
                                    values.company = value;
                                    setValues(values);
                                }}
                                errorMessage={
                                    <ErrorMessage
                                        name="company"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                className={`input ${
                                    errors.company &&
                                    touched.company &&
                                    'select-error'
                                }`}
                                placeholder={__('اختر...', 'Select...')}
                            />

                            <Select
                                options={EmployeesCountSelectOptions}
                                labelText={__(
                                    'عدد الموظفين',
                                    'Number of Employees'
                                )}
                                name="employees_count"
                                value={values.employees_count}
                                onChange={(value) => {
                                    values.employees_count = value;
                                    setValues(values);
                                }}
                                errorMessage={
                                    <ErrorMessage
                                        name="employees_count"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                className={`input ${
                                    errors.employees_count &&
                                    touched.employees_count &&
                                    'select-error'
                                }`}
                                placeholder={__('اختر...', 'Select...')}
                            />

                            <Input
                                inputType="text"
                                label={__('اسم المنشأة', 'Company Name')}
                                name="company_name"
                                value={values.company_name}
                                onChange={handleChange}
                                errorMessage={
                                    <ErrorMessage
                                        name="company_name"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                placeholder={__(
                                    'ما اسم منشأتك؟',
                                    "What's your company name?"
                                )}
                                className={`input ${
                                    errors.company_name &&
                                    touched.company_name &&
                                    'input-error'
                                }`}
                            />

                            <Input
                                inputType="phone"
                                label={__('رقم الجوال', 'Phone Number')}
                                name="mobile"
                                value={values.mobile}
                                onChange={(val) => {
                                    values.mobile = val;
                                    setValues(values);
                                }}
                                errorMessage={
                                    <ErrorMessage
                                        name="mobile"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                placeholder={__(
                                    'ما رقم جوالك؟',
                                    "What's phone number?"
                                )}
                                country_id="SA"
                                international
                                withCountryCallingCode
                                className={`input ${
                                    errors.mobile &&
                                    touched.mobile &&
                                    'input-error'
                                }`}
                            />

                            <Input
                                inputType="email"
                                label={__('البريد الإلكتروني', 'Email Address')}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                errorMessage={
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                placeholder={__(
                                    'اكتب رسالتك لفريقنا هنا',
                                    'Write your message for the team here'
                                )}
                                className={`input ${
                                    errors.email &&
                                    touched.email &&
                                    'input-error'
                                }`}
                            />

                            <Input
                                inputType="text"
                                label={__('الموقع الإلكتروني', 'Website')}
                                name="website"
                                value={values.website}
                                onChange={handleChange}
                                errorMessage={
                                    <ErrorMessage
                                        name="website"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                placeholder={__(
                                    'ما رابط موقع منشأتك؟',
                                    "What's your company's website link?"
                                )}
                                className={`input ${
                                    errors.website &&
                                    touched.website &&
                                    'input-error'
                                }`}
                            />

                            <Input
                                inputType="textarea"
                                label={__('الرسالة', 'Message')}
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                errorMessage={
                                    <ErrorMessage
                                        name="message"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                placeholder={__(
                                    'اكتب رسالتك لفريقنا هنا',
                                    'Write your message for the team here'
                                )}
                                className={`input ${
                                    errors.message &&
                                    touched.message &&
                                    'input-error'
                                }`}
                            />

                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                title={__('إرسال', 'Send')}
                                colors="primaryWithBg"
                                width="120px"
                            />
                        </form>
                    )}
                </Formik>
            </ContentWrapper>
        </Container>
    );
};

export default ContactSection;
