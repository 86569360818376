import React from "react";
import InfoWrapper from "./Info.style";
import { useIntl } from "gatsby-plugin-intl";

function Info({ title, icon, iconBackgroundColor }) {
    const { locale } = useIntl();

    return (
        <InfoWrapper iconBackgroundColor={iconBackgroundColor} lang={locale}>
            <span>{icon}</span>
            <p>{title}</p>
        </InfoWrapper>
    );
}

export default Info;
