import styled from 'styled-components';

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 150px;

    @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
    }

    .textWrapper {
        width: 49%;
        color: #212141;

        @media (max-width: 991px) {
            width: 90%;
        }

        h2 {
            margin: 0;
            font-size: 21px;

            @media (max-width: 651px) {
                font-size: 16px;
            }
        }
    }

    .imageWrapper {
        max-width: 400px;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .socialWrapper {
        margin-bottom: 30px;
        flex-wrap: wrap;
        display: flex;

        & > div {
            ${({ lang }) =>
                lang === 'ar' ? 'padding-right: 16px;' : 'padding-left: 16px;'}
        }

        p {
            ${({ lang }) =>
                lang === 'ar' ? 'margin: 0 0 0 10px;' : 'margin: 0;'}
        }

        @media (max-width: 651px) {
            justify-content: flex-start;
        }

        @media (max-width: 451px) {
            & > div {
                margin-bottom: 15px;
            }
            flex-direction: column;
        }
    }

    .inputWrapper {
        width: 47%;

        @media (max-width: 991px) {
            width: 90%;
        }

        textarea {
            resize: none;
        }

        label {
            color: #000;
        }
    }
`;

export default ContentWrapper;
